import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { request } from '@rd-web-markets/shared/dist/services/request';
const api = buildService('/companies/{id}/researchers', {
  only: ['all', 'create', 'update']
});
const researchersService = {
  useFetchAllResearchers: (companyId, setLoading) => {
    const [researchers, setResearchers] = useState([]);
    const fetchAllResearchers = useErrorHandling(useCallback(async () => {
      setLoading && setLoading(true);
      const allCompanyResearchers = await api.all(companyId);
      setResearchers([...allCompanyResearchers]);
    }, [companyId, setLoading, setResearchers]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    useEffect(() => {
      fetchAllResearchers();
    }, [fetchAllResearchers]);
    return [researchers, setResearchers, fetchAllResearchers];
  },
  useUpdateResearcher: (companyId, researchers, setResearchers, setLoading) => {
    const updateResearcher = useErrorHandling(useCallback(async researcher => {
      setLoading && setLoading(true);
      await api.update(companyId, researcher.id, {
        researcher
      });
    }, [companyId, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return updateResearcher;
  },
  useCreateResearcher: (companyId, researchers, setResearchers, setLoading) => {
    const updateResearcher = useErrorHandling(useCallback(async researcher => {
      setLoading && setLoading(true);
      await api.create(companyId, {
        researcher
      });
    }, [companyId, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return updateResearcher;
  },
  delete(companyId, researcherId) {
    return fetch("/api/companies/".concat(companyId, "/researchers/").concat(researcherId), request.delete);
  }
};
researchersService.useCrud = (companyId, setLoading) => {
  const [researchers, setResearchers, fetchAllResearchers] = researchersService.useFetchAllResearchers(companyId, setLoading);
  const updateResearcher = researchersService.useUpdateResearcher(companyId, researchers, setResearchers, setLoading);
  const createResearcher = researchersService.useCreateResearcher(companyId, researchers, setResearchers, setLoading);
  return {
    researchers,
    setResearchers,
    fetchAllResearchers,
    updateResearcher,
    createResearcher
  };
};
export default researchersService;