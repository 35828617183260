import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import { useCallback, useEffect, useState } from 'react';
import { request } from '@rd-web-markets/shared/dist/services/request';
const api = buildService('/companies/{id}/programs', {
  only: ['all', 'create', 'update', 'delete']
});
const claimGroupProgramsApi = buildService('/claim_groups/{id}/programs', {
  only: ['all']
});
const programsService = {
  useFetchAllPrograms: (companyId, setLoading) => {
    const [programs, setPrograms] = useState([]);
    const fetchAllPrograms = useErrorHandling(useCallback(async () => {
      setLoading && setLoading(true);
      const allCompanyPrograms = await api.all(companyId);
      setPrograms([...allCompanyPrograms]);
    }, [companyId, setLoading, setPrograms]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    useEffect(() => {
      if (!companyId) return null;
      fetchAllPrograms();
    }, [fetchAllPrograms, companyId]);
    return [programs, setPrograms, fetchAllPrograms];
  },
  useFetchClaimGroupPrograms: (claimGroupId, setLoading) => {
    const [claimGroupPrograms, setClaimGroupPrograms] = useState([]);
    const fetchClaimGroupPrograms = useErrorHandling(useCallback(async function () {
      let setStateAfterFetch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      setLoading && setLoading(true);
      const allClaimGroupPrograms = await claimGroupProgramsApi.all(claimGroupId);
      if (setStateAfterFetch) {
        setClaimGroupPrograms([...allClaimGroupPrograms]);
      }
      return allClaimGroupPrograms;
    }, [claimGroupId, setLoading, setClaimGroupPrograms]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    useEffect(() => {
      if (!claimGroupId) return null;
      fetchClaimGroupPrograms();
    }, [fetchClaimGroupPrograms, claimGroupId]);
    return [claimGroupPrograms, setClaimGroupPrograms, fetchClaimGroupPrograms];
  },
  useUpdateProgram: (companyId, programs, setPrograms, setLoading) => {
    const updateProgram = useErrorHandling(useCallback(async program => {
      setLoading && setLoading(true);
      const updatedProgram = await api.update(companyId, program.id, {
        program
      });
    }, [companyId, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return updateProgram;
  },
  useCreateProgram: (companyId, programs, setPrograms, setLoading) => {
    const createProgram = useErrorHandling(useCallback(async program => {
      setLoading && setLoading(true);
      await api.create(companyId, {
        program
      });
    }, [companyId, setLoading]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return createProgram;
  },
  useDeleteProgram(companyId, programs, setPrograms, setLoading) {
    const deleteProgram = useErrorHandling(useCallback(async program => {
      await api.delete(companyId, program.id);
      setPrograms(programs.filter(r => r.id !== program.id));
    }, [companyId, programs, setPrograms]), useCallback(async () => {
      setLoading && setLoading(false);
    }, [setLoading]));
    return deleteProgram;
  }
};
programsService.useCrud = function (companyId, setLoading) {
  let claimGroupId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  const [programs, setPrograms, fetchAllPrograms] = programsService.useFetchAllPrograms(companyId, setLoading);
  const [claimGroupPrograms, setClaimGroupPrograms, fetchClaimGroupPrograms] = programsService.useFetchClaimGroupPrograms(claimGroupId, setLoading);
  const updateProgram = programsService.useUpdateProgram(companyId, programs, setPrograms, setLoading);
  const createProgram = programsService.useCreateProgram(companyId, programs, setPrograms, setLoading);
  const deleteProgram = programsService.useDeleteProgram(companyId, programs, setPrograms, setLoading);
  return {
    programs,
    setPrograms,
    fetchAllPrograms,
    updateProgram,
    createProgram,
    deleteProgram,
    claimGroupPrograms,
    setClaimGroupPrograms,
    fetchClaimGroupPrograms
  };
};
export default programsService;