import { CROSS_MARKET_EMAIL_DYNAMIC_FIELDS } from "@rd-web-markets/shared/dist/util/constants";
export const UTR_TEXT = 'UTR';
export const SPRINT_CLAIM_NAME = 'CA_Sprint_Claim';
export const TEMPLATE_PAGE_TITLE = 'Claim Template Listing';
export const CLAIM_GROUP_ENGAGEMENT_TITLE = 'contacts_email_kick_off_meeting_organization';
export const COMPANY_REQUIRES_COMPANIES_HOUSE_INFO = false;
export const CLAIM_SELECTION_ALLOWS_SLICING_MULTI_YEAR_CLAIMS = false;
export const EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS = [{
  field: 'accounting_period_end_date',
  title: 'Accounting Period End Date',
  editableInModal: true
}, {
  field: 'accounting_period_start_date',
  title: 'Accounting Period Start Date',
  editableInModal: true
}, {
  field: 'total_qualifying_rnd_expenditure',
  title: 'Total Qualifying RND Expenditure'
}];
export const EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS = [{
  field: 'company_overview_rnd_context',
  title: 'Company Overview RND Context'
}, {
  field: 'contact_to_collaborate_on_rnd',
  title: 'Contact To Collaborate',
  editableInModal: true
}];
export const EDITOR_CLAIM_PROJECT_FIELDS = [];
export const NEW_COMPANY_NEEDS_COMPANY_NUMBER = false;
export const CLAIM_GROUP_DEFAULT_PARAMS = {
  consultants_attributes: [],
  project_methodology: 'employee_basis',
  rnd_collaborate_contacts_attributes: [],
  has_overdue_taxes: false,
  has_staff_involved_not_on_payroll: false,
  accounts_provided: false,
  tax_computations_provided: false,
  development_costs_capitalised: false,
  contracted_by_third_party: false,
  received_grants_or_subsidies: false,
  reimburses_staff_expenses: false,
  purchased_company_cars: false,
  has_patents: false
};
export const CLAIM_GROUP_EDIT_FORM_FIELDS = {
  showSicCodesString: true,
  showProjectMehotdology: true,
  showOverdueTaxesSection: true,
  showOverdueTaxesQuestion: true,
  showDoesCompanyReimburseStaffExpenses: true,
  showDoesCompanyPayCarAllowance: true
};
export const SHOULD_SHOW_COST_METHODOLOGY = false;
export const SHOULD_SHOW_OVERDUE_TAXES = true;
export const CLIENT_CONTACT_TYPES = ['Client Payables', 'Client Sponsor', 'Financial Contact', 'Technical Contact'];
export const EXTERNAL_CONTACT_TYPES = ['Financial Auditor', 'Technical Auditor'];
export const CLAIM_GROUP_EDIT_PAGE_SHOW_YEAR_END_DROPDOWN = false;
export const CLAIM_GROUP_SHOW_ALL_PROJECT = false;
export const APP_FOOTER_TEXT = 'R&D Tax Credits Portal by Ayming';
export const APP_RELEASE_VERSION = 'Release 1.106.1';
export const LOCALE = 'en-CA';
export const ENGINE_NAME = 'CaSection';
export const EMAIL_TEMPLATE_DYNAMIC_FIELDS = {
  email_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  client_cost_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  claim_schedule_ics_event_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  self_declaration_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  mission_email_notification: []
};
export const SHOW_CLAIM_GROUP_TASKS = true;
export const SHOW_COMPANY_CLAIM_GROUP_EDIT_FORM_FIELDS = true;
export const MARKET_SPECIFIC_RD_OR_4 = 'R&D';
export const MARKET_SPECIFIC_RD_OR_4_MENU = "".concat(MARKET_SPECIFIC_RD_OR_4, " Technical");
export const TEMPLATE_HEADER_TITLE = {
  email_template: 'Configure Kick Off Template',
  client_cost_template: 'Configure Client Cost Template',
  claim_schedule_ics_event_template: 'Configure Ics Event Template',
  self_declaration_template: 'Configure Self - Declaration Email Template',
  kick_off: 'Configure Kick Off Template'
};
export const REPORT_SAVE_PARAM = '';
export const TASK_TITLE = 'claim_group_tasks';
export const LOCAL_LANGUAGES = ['en', 'fr', 'nl'];
export const REPORT_TYPE_INCLUDE_SECTION_COLUMN_NAME = {
  technical: 'include_in_technical_report'
};
export const CURRENCY_SYMBOL = '$';
export const SIDEBAR_MENU_ITEMS = [{
  text: 'edit_claim_info',
  link: 'edit/general'
}, {
  text: 'project_overview',
  link: 'project_overview'
}, {
  text: 'engagement_team',
  link: 'engagement_team'
}, {
  text: 'claim_costs',
  link: 'claim_costs',
  subMenu: true,
  showLastFourChars: true
}, {
  text: 'dashboard',
  link: 'claim_group_dashboard'
}];
export const SIDEBAR_NAV_ADMIN_LINKS = [{
  text: 'companies',
  link: 'companies'
}, {
  text: 'claims',
  link: 'claims',
  subMenu: [{
    text: 'my_claims',
    link: 'my_claim_groups'
  }, {
    text: 'all_claims',
    link: 'all_claim_groups'
  }]
}, {
  text: 'users',
  link: 'users'
}, {
  text: 'Templates',
  link: 'templates',
  subMenu: [{
    text: 'claim_templates',
    link: 'claim_template'
  }, {
    text: 'project_description_t661',
    link: 'report_templates'
  }, {
    text: 'kick_off_email_template',
    link: 'kickoff_email_template'
  }, {
    text: 'claim_schedule_ics_event_template',
    link: 'claim_schedule_ics_event_template'
  }, {
    text: 'client_cost_template',
    link: 'client_cost_template'
  }, {
    text: 'mission_email_notificaiton_templates',
    link: 'mission_email_notification_templates'
  }, {
    text: 'project_questionnaire_template',
    link: 'project_questionnaire_template'
  }]
}, {
  text: 'documents',
  link: 'documents'
}, {
  text: 'translations',
  link: 'translations'
}];
export const SIDEBAR_NAV_CLIENT_LINKS = [{
  text: 'all_claims',
  link: ''
}];
export const SIDEBAR_NAV_ACCOUNTANT_LINKS = [{
  text: 'all_claims',
  link: ''
}];
export const SIDEBAR_NAV_CONSULTANT_LINKS = [{
  text: 'companies',
  link: 'companies'
}, {
  text: 'claims',
  link: 'my_claims'
}];
export const SIDEBAR_NAV_SUPER_CONSULTANT_LINKS = [{
  text: 'companies',
  link: 'companies'
}, {
  text: 'claims',
  link: 'claims',
  subMenu: [{
    text: 'my_claims',
    link: 'my_claim_groups'
  }, {
    text: 'all_claims',
    link: 'all_claim_groups'
  }]
}];
export const SIDEBAR_CLAIM_COSTS_LINKS = [{
  text: 'Upload/Download Cost Template',
  link: 'financial_details/upload'
}, {
  text: 'Cost Categories',
  link: 'financial_details/prorata_details'
}];
export const SIDEBAR_CLAIM_COSTS_LINKS_CUSTOMER = [{
  text: 'Upload/Download Cost Template',
  link: 'financial_details/upload'
}, {
  text: 'Cost Categories',
  link: 'financial_details/prorata_details'
}];
export const SIDEBAR_CLAIM_COSTS_LINKS_ACCOUNTANT = [{
  text: 'Cost Categories',
  link: 'financial_details/general'
}];
export const SIDEBAR_TECHNICAL_SECTION_LINKS = [{
  text: 'company_info',
  link: 'company_info'
}, {
  text: 'project_reports',
  link: 'project_reports',
  subMenu: true
}, {
  text: 'templates',
  link: 'upload_template',
  subMenu: [{
    text: 'upload_download_project_template',
    link: 'upload_template'
  }, {
    text: 'upload_download_technical_narrative_template',
    link: 'upload_narrative_template'
  }]
}, {
  text: 'documents',
  link: 'technical_uploads'
}];
export const SIDEBAR_CLAIM_OVERVIEW_LINKS_CUSTOMER = [{
  text: 'Claim Cost',
  link: 'claim_cost',
  subMenu: true
}, {
  text: 'R&D Technical',
  link: 'technical_section'
}];
export const SIDEBAR_NAV_RND_REPORT_PAGE_CLIENT_LINKS = {
  link: 'companies',
  text: 'Companies'
};
export const CURRENCY_FORMATTER = new Intl.NumberFormat(LOCALE);
export const PERCENTAGE_FORMATTER = new Intl.NumberFormat(LOCALE, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
});
export const ENGAGEMENT_TEAM_ROLES = [{
  value: 'client_sponsor'
}, {
  value: 'financial_contact'
}, {
  value: 'technical_contact'
}, {
  value: 'other_stakeholder'
}, {
  value: 'auditor'
}];
export const CLIENT_RND_EDIT_DYNAMIC_FIELD = true;
export const CLIENT_RND_SELECT_PROJECT_POPOVER = true;
export const SUPPORT_EMAIL_ADDRESS = 'be.support.advance@ayming.com';
export const ATTACHMENT_OPTIONS = {
  attachCostTemplate: false,
  technicalTemplateField: false,
  includeTechnicalUploadField: false,
  includeCostTemplateLink: false
};
export const SHOW_DOWNLOAD_TEMPLATE_BUTTON = true;
export const TEMPLATE_SELECT_MODEL_NAME = 'report_templates';
export const PROJECT_FIELD_MODEL_NAME = 'project_description_fields';
export const SHOULD_CREATE_REPORT = false;
export const modifyReportTemplateCategoriesForProject = [0, 1]; //at index 0, removes 1 element
export const CONSULTANT_ROLE_NAME = 'consultant';
export const SHOW_DEPARTMENT_FOR_USER_FORM = false;
export const SHOULD_PROJECT_POPOVER_EXCLUDE_OTHER_PROJECTS = true;
export const TINYMCE_MARKET_REPORT_MODULES = ['styles_formatter', 'track_changes', 'comments', 'resize_image_when_added'];
export const TINYMCE_MARKET_EMAIL_MODULES = ['styles_formatter', 'resize_image_when_added'];
export const TINYMCE_MARKET_OTHER_MODULES = ['styles_formatter', 'track_changes', 'comments', 'resize_image_when_added'];
export const SHOW_COST_MENU_IN_MASTER = false;
export const SHOW_SIDE_BAR_IN_CLAIM_GROUP_OVERVIEW = true;
export const FINANCIAL_SECTION_GENERAL_LINK = 'prorata_details';
export const SHOULD_PRESET_COMPANY_CLAIMGROUP_FORM_FILEDS = true;
export const SHOULD_AUTOSAVE = false;
export const SHOULD_SHOW_ADD_USER_BUTTON_FOR_ADMINS = true;
export const SHOULD_SHOW_ADMIN_OPTION_FOR_CONSULTANTS = true;
export const IS_PROJECT_DATE_SHORT_FORMAT = true;
export const SHOULD_SHOW_DATE_IN_SUMMARY_SECTION = false;
export const RATES_SCHEME = {
  'Enhancement Rates': ['rate'],
  'Credit Rates': ['rate'],
  'Corporation Tax Rates': ['rate'],
  'NI Rates': ['rate'],
  'NI Thresholds': ['threshold']
};
export const SHOULD_ADD_REPORT_TEMPLATE_SEARCH = false;
export const SHOULD_SHOW_TEMPLATE_TITLE = false;
export const SHOW_SEND_CLAIM_BUTTON = false;
export const PROJECT_REPORT_DATE_PICKER_TYPE = 'month';
export const SHOW_CLAIM_GROUP_PROJECT_ELSTER = false;
export const SHOW_PROJECT_TEMPLATE_SELECT = false;
export const SHOW_IT_TYPE_SELECT = true;
export const SHOW_PROJECT_GROUPING_TAB_ON_PROJECT_OVERVIEW_PAGE = false;
export const SHOW_MARKET_PROGRAM_LIST = true;
export const PROJECT_GROUP_RPOGRAM_TYPE_NAME = 'program';
export const PROJECT_OVERVIEW_TABLE = {
  headers: [{
    text: 'project_name',
    sortable: true,
    orderBy: 'project_name',
    filterableByText: true,
    filterBy: 'project_name'
  }, {
    text: 'project_code',
    sortable: true,
    orderBy: 'code',
    filterableByText: true,
    filterBy: 'code'
  }, {
    text: 'nature_of_project',
    sortable: true,
    orderBy: 'nature_of_project',
    filterableByText: true,
    filterBy: 'nature_of_project'
  }, {
    text: 'belspo_identification_number',
    sortable: true,
    orderBy: 'belspo_identification_number',
    filterableByText: true,
    filterBy: 'belspo_identification_number'
  }, {
    text: 'project_eligibility',
    sortable: true,
    orderBy: 'project_eligibility',
    filterableByText: true,
    filterBy: 'project_eligibility'
  }, {
    text: ''
  }, {
    text: ''
  }],
  columns: ['project_name', 'internal_project_number', 'nature_of_project', 'belspo_identification_number', 'project_eligibility', '']
};
export const SHOW_QUESTIONNAIRES_SELECT_SEPARATELY = false;
export const SHOW_REGULAR_REALTIME_DASHBOARD = false;
export const SHOW_ADD_CLAIM_GROUP_BTN_ON_MASTER_PAGE = false;
export const SHOULD_SHOW_ADD_CLAIM_BUTTON_IN_CLAIM_GROUP_SIDEBAR = false;
export const SHOULD_SHOW_ADD_COMPANY_BUTTON = false;
export const SHOULD_SHOW_ADD_USERS_TO_COMPANY = true;
export const SHOULD_SHOW_CREATE_PROJECTS_PAGE_FOR_CUSTOMER = true;
export const SHOW_EDIT_BUTTON_FOR_RESTRICTED_FOLDER = false;
export const FILE_MANAGEMENT_COLUMN_ORDER = ['folderIcon', 'name', 'notes', 'uploadedBy', 'createdAt', 'editButton'];
export const SIDEBAR_MENU_ITEMS_CUSTOMER = [{
  text: 'edit_claim_info',
  link: 'edit/general'
}, {
  text: 'project_overview',
  link: 'project_overview'
}];
export const SHOW_COMPANY_GROUPS_HEADER = true;
export const CLAIM_GROUP_TASKS_WITH_NOTIFICATIONS = {
  kom: {
    onStageComplete: true,
    triggerTasks: []
  },
  tom: {
    onStageComplete: false,
    triggerTasks: ['set_up_the_take_off_meeting']
  },
  data_collect_analysis_data: {
    onStageComplete: false,
    triggerTasks: []
  },
  calculation: {
    onStageComplete: true,
    triggerTasks: []
  },
  implementation: {
    onStageComplete: false,
    triggerTasks: []
  },
  landing: {
    onStageComplete: false,
    triggerTasks: []
  },
  belspo: {
    onStageComplete: false,
    triggerTasks: []
  }
};
export const SHOW_ADD_PROJECTS_BUTTON_ON_MASTER_PAGE = false;
export const SHOW_IMPORT_PROJECT_AT_PROJECT_OVERVIEW = true;
export const SHOW_SEND_QUESTIONNAIRE_BUTTON_ON_PROJECT_OVERVIEW_PAGE = false;
export const SHOW_PROJECT_QUESTIONNAIRE_BUTTON_ON_PROJECT_OVERVIEW_PAGE = true;
export const SHOW_QUESTIONNAIRES_TAB_IN_PROJECT_OVERVIEW = true;