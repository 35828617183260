import { buildQueryString, buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';
const api = buildService('/companies/{id}/claim_project_reports', {
  only: ['all']
});
const useFetchCompanyProjects = _ref => {
  let {
    companyId,
    setLoading,
    initialQueryParams
  } = _ref;
  const [companyProjects, setCompanyProjects, fetchProjects] = serviceMethods.useFetchCollection({
    fetchCallback: useCallback(async queryParams => await api.all(companyId, {
      query: buildQueryString(queryParams)
    }), [companyId]),
    setLoading,
    initialQueryParams: initialQueryParams || {}
  });
  return [companyProjects, setCompanyProjects, fetchProjects];
};
const useUpdateCompanyProject = _ref2 => {
  let {
    companyId
  } = _ref2;
  const updateProject = serviceMethods.useUpdate(useCallback(async claim_project_report => {
    return await api.update(companyId, claim_project_report.id, {
      claim_project_report
    });
  }, [companyId]));
  return updateProject;
};
const companyProjectService = {
  // base: api.base,
  useFetchCompanyProjects,
  useUpdateCompanyProject
};
export default companyProjectService;